<template>
  <!-- 员工中心 -->
  <AppBar>
    <div class="top_box">
      <el-form :inline="true" label-width="90px">
        <el-form-item label="代码：" prop="mobile">
          <el-input
            placeholder="请输入代码"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
            oninput="value=value.replace(/[^\d]/g,'')"
          />
        </el-form-item>
        <el-form-item label="名称" prop="bookCategoryId">
          <el-input
            placeholder="请输入名称"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
            oninput="value=value.replace(/[^\d]/g,'')"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="handleQuery"
            >搜索</el-button
          >
          <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="body_box">
      <el-table :data="row">
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column label="代码" align="center" prop="code" />
        <el-table-column label="名称" align="center" prop="name" />
        <el-table-column label="是否启用" align="center" prop="wxNickName">
          <template slot-scope="scope">{{
            scope.row.status == "0" ? "启用" : "禁用"
          }}</template>
        </el-table-column>
        <el-table-column label="上级组织" align="center" prop="namePath" />
        <el-table-column label="简称" align="center" prop="upper.name" />
        <el-table-column label="电话" align="center" prop="upper.phone" />
        <el-table-column label="地址" align="center" prop="namePath" />
        <!-- <el-table-column label="联系人" align="center" prop="wxNickName" />
        <el-table-column label="最后操作信息" align="center" prop="createTime">
          <template slot-scope="scope">
            {{
              `${memberList[scope.$index].updateBy || ""} ${memberList[
                scope.$index
              ].updateTime || ""}`
            }}
          </template>
        </el-table-column> -->
      </el-table>
    </div>
  </AppBar>
</template>

<style lang="less" scoped>
.content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: white;
  > i {
    width: 360px;
  }
  .box {
    position: relative;
    width: 360px;
    height: 170px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;

    .main {
      position: relative;
      width: 86%;
      height: 80%;
      display: flex;
      flex-direction: row;
      align-items: center;

      .one {
        position: relative;
        height: 100%;
        width: 36%;

        .el-image {
          height: 100%;
          width: 100%;
          border-radius: 4px;
        }
      }
      .two {
        margin-left: 20px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        > p {
          color: #afafaf;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          max-width: 120px;

          > span {
            color: #696c70;
          }
        }
      }
    }
  }
}
</style>

<script>
import AppBar from "@/components/AppBar";
export default {
  data() {
    return {
      id: 0,
      isShowPop: false,
      dataStaffs: [],
      userData: {},
      currentPage: 1,
      total: 0,
      row: [
        {
          uuid: "8a2eb81358aa8aac0158bd5ec1f51037",
          version: 0,
          createInfo: {
            time: 1480645853000,
            operator: {
              id: "0",
              operName: "系统管理员"
            },
            enterprise: null
          },
          lastModifyInfo: {
            time: 1480645853000,
            operator: {
              id: "0",
              operName: "系统管理员"
            },
            enterprise: null
          },
          enterprise: "8a2eb813518f1c5801518f1c6c080089",
          code: "yztest",
          name: "测试业主",
          shortName: "",
          phone: "",
          address: "",
          contact: "",
          attachmentId: null,
          enabled: true,
          upper: {
            uuid: "8a2eb813518f1c5801518f1c6c080089",
            version: 1,
            createInfo: {
              time: 1449763200000,
              operator: {
                id: "=system=",
                operName: "系统用户"
              },
              enterprise: null
            },
            lastModifyInfo: {
              time: 1449763200000,
              operator: {
                id: "=system=",
                operName: "系统用户"
              },
              enterprise: null
            },
            enterprise: "8a2eb813518f1c5801518f1c6c080089",
            code: "0000",
            name: "总部",
            shortName: null,
            phone: null,
            address: null,
            contact: null,
            attachmentId: null,
            enabled: true,
            upper: null,
            level: 1,
            path: "/0000",
            namePath: "/总部",
            authentication: "211534962",
            isLeaf: false,
            storeType: null,
            orgType: null
          },
          level: 2,
          path: "/0000/yztest",
          namePath: "/总部/测试业主",
          authentication: null,
          isLeaf: null,
          storeType: null,
          orgType: "owner"
        },
        {
          uuid: "8a2eb813584c1f3401584c2a94cd018b",
          version: 0,
          createInfo: {
            time: 1478746608000,
            operator: {
              id: "0",
              operName: "系统管理员"
            },
            enterprise: null
          },
          lastModifyInfo: {
            time: 1478746608000,
            operator: {
              id: "0",
              operName: "系统管理员"
            },
            enterprise: null
          },
          enterprise: "8a2eb813518f1c5801518f1c6c080089",
          code: "9001",
          name: "DODO一区",
          shortName: "",
          phone: "",
          address: "",
          contact: "",
          attachmentId: null,
          enabled: true,
          upper: {
            uuid: "8a2eb813518f1c5801518f1c6c080089",
            version: 1,
            createInfo: {
              time: 1449763200000,
              operator: {
                id: "=system=",
                operName: "系统用户"
              },
              enterprise: null
            },
            lastModifyInfo: {
              time: 1449763200000,
              operator: {
                id: "=system=",
                operName: "系统用户"
              },
              enterprise: null
            },
            enterprise: "8a2eb813518f1c5801518f1c6c080089",
            code: "0000",
            name: "总部",
            shortName: null,
            phone: null,
            address: null,
            contact: null,
            attachmentId: null,
            enabled: true,
            upper: null,
            level: 1,
            path: "/0000",
            namePath: "/总部",
            authentication: "211534962",
            isLeaf: false,
            storeType: null,
            orgType: null
          },
          level: 2,
          path: "/0000/9001",
          namePath: "/总部/DODO一区",
          authentication: null,
          isLeaf: null,
          storeType: null,
          orgType: "operateArea"
        },
        {
          uuid: "ebc5c818991b11e6b6816be1261807db",
          version: 0,
          createInfo: {
            time: 1477225684000,
            operator: {
              id: "admin",
              operName: "超级管理员"
            },
            enterprise: null
          },
          lastModifyInfo: {
            time: 1477225684000,
            operator: {
              id: "admin",
              operName: "超级管理员"
            },
            enterprise: null
          },
          enterprise: "8a2eb813518f1c5801518f1c6c080089",
          code: "1001",
          name: "镇江大区",
          shortName: "",
          phone: "",
          address: "",
          contact: "",
          attachmentId: null,
          enabled: true,
          upper: {
            uuid: "8a2eb813518f1c5801518f1c6c080089",
            version: 1,
            createInfo: {
              time: 1449763200000,
              operator: {
                id: "=system=",
                operName: "系统用户"
              },
              enterprise: null
            },
            lastModifyInfo: {
              time: 1449763200000,
              operator: {
                id: "=system=",
                operName: "系统用户"
              },
              enterprise: null
            },
            enterprise: "8a2eb813518f1c5801518f1c6c080089",
            code: "0000",
            name: "总部",
            shortName: null,
            phone: null,
            address: null,
            contact: null,
            attachmentId: null,
            enabled: true,
            upper: null,
            level: 1,
            path: "/0000",
            namePath: "/总部",
            authentication: "211534962",
            isLeaf: false,
            storeType: null,
            orgType: null
          },
          level: 2,
          path: "/0000/1001",
          namePath: "/总部/镇江大区",
          authentication: null,
          isLeaf: null,
          storeType: null,
          orgType: "operateArea"
        },
        {
          uuid: "ebc5cd50991b11e6b6816be1261807db",
          version: 0,
          createInfo: {
            time: 1477225684000,
            operator: {
              id: "admin",
              operName: "超级管理员"
            },
            enterprise: null
          },
          lastModifyInfo: {
            time: 1477225684000,
            operator: {
              id: "admin",
              operName: "超级管理员"
            },
            enterprise: null
          },
          enterprise: "8a2eb813518f1c5801518f1c6c080089",
          code: "1002",
          name: "常州大区",
          shortName: "",
          phone: "",
          address: "",
          contact: "",
          attachmentId: null,
          enabled: true,
          upper: {
            uuid: "8a2eb813518f1c5801518f1c6c080089",
            version: 1,
            createInfo: {
              time: 1449763200000,
              operator: {
                id: "=system=",
                operName: "系统用户"
              },
              enterprise: null
            },
            lastModifyInfo: {
              time: 1449763200000,
              operator: {
                id: "=system=",
                operName: "系统用户"
              },
              enterprise: null
            },
            enterprise: "8a2eb813518f1c5801518f1c6c080089",
            code: "0000",
            name: "总部",
            shortName: null,
            phone: null,
            address: null,
            contact: null,
            attachmentId: null,
            enabled: true,
            upper: null,
            level: 1,
            path: "/0000",
            namePath: "/总部",
            authentication: "211534962",
            isLeaf: false,
            storeType: null,
            orgType: null
          },
          level: 2,
          path: "/0000/1002",
          namePath: "/总部/常州大区",
          authentication: null,
          isLeaf: null,
          storeType: null,
          orgType: "operateArea"
        },
        {
          uuid: "ebc5ced1991b11e6b6816be1261807db",
          version: 0,
          createInfo: {
            time: 1477225684000,
            operator: {
              id: "admin",
              operName: "超级管理员"
            },
            enterprise: null
          },
          lastModifyInfo: {
            time: 1477225684000,
            operator: {
              id: "admin",
              operName: "超级管理员"
            },
            enterprise: null
          },
          enterprise: "8a2eb813518f1c5801518f1c6c080089",
          code: "1003",
          name: "南通大区",
          shortName: "",
          phone: "",
          address: "",
          contact: "",
          attachmentId: null,
          enabled: true,
          upper: {
            uuid: "8a2eb813518f1c5801518f1c6c080089",
            version: 1,
            createInfo: {
              time: 1449763200000,
              operator: {
                id: "=system=",
                operName: "系统用户"
              },
              enterprise: null
            },
            lastModifyInfo: {
              time: 1449763200000,
              operator: {
                id: "=system=",
                operName: "系统用户"
              },
              enterprise: null
            },
            enterprise: "8a2eb813518f1c5801518f1c6c080089",
            code: "0000",
            name: "总部",
            shortName: null,
            phone: null,
            address: null,
            contact: null,
            attachmentId: null,
            enabled: true,
            upper: null,
            level: 1,
            path: "/0000",
            namePath: "/总部",
            authentication: "211534962",
            isLeaf: false,
            storeType: null,
            orgType: null
          },
          level: 2,
          path: "/0000/1003",
          namePath: "/总部/南通大区",
          authentication: null,
          isLeaf: null,
          storeType: null,
          orgType: "operateArea"
        },
        {
          uuid: "ebc5d023991b11e6b6816be1261807db",
          version: 0,
          createInfo: {
            time: 1477225684000,
            operator: {
              id: "admin",
              operName: "超级管理员"
            },
            enterprise: null
          },
          lastModifyInfo: {
            time: 1477225684000,
            operator: {
              id: "admin",
              operName: "超级管理员"
            },
            enterprise: null
          },
          enterprise: "8a2eb813518f1c5801518f1c6c080089",
          code: "1004",
          name: "扬州大区",
          shortName: "",
          phone: "",
          address: "",
          contact: "",
          attachmentId: null,
          enabled: true,
          upper: {
            uuid: "8a2eb813518f1c5801518f1c6c080089",
            version: 1,
            createInfo: {
              time: 1449763200000,
              operator: {
                id: "=system=",
                operName: "系统用户"
              },
              enterprise: null
            },
            lastModifyInfo: {
              time: 1449763200000,
              operator: {
                id: "=system=",
                operName: "系统用户"
              },
              enterprise: null
            },
            enterprise: "8a2eb813518f1c5801518f1c6c080089",
            code: "0000",
            name: "总部",
            shortName: null,
            phone: null,
            address: null,
            contact: null,
            attachmentId: null,
            enabled: true,
            upper: null,
            level: 1,
            path: "/0000",
            namePath: "/总部",
            authentication: "211534962",
            isLeaf: false,
            storeType: null,
            orgType: null
          },
          level: 2,
          path: "/0000/1004",
          namePath: "/总部/扬州大区",
          authentication: null,
          isLeaf: null,
          storeType: null,
          orgType: "operateArea"
        },
        {
          uuid: "ebc5d170991b11e6b6816be1261807db",
          version: 0,
          createInfo: {
            time: 1477225684000,
            operator: {
              id: "admin",
              operName: "超级管理员"
            },
            enterprise: null
          },
          lastModifyInfo: {
            time: 1477225684000,
            operator: {
              id: "admin",
              operName: "超级管理员"
            },
            enterprise: null
          },
          enterprise: "8a2eb813518f1c5801518f1c6c080089",
          code: "1005",
          name: "杭州大区",
          shortName: "",
          phone: "",
          address: "",
          contact: "",
          attachmentId: null,
          enabled: true,
          upper: {
            uuid: "8a2eb813518f1c5801518f1c6c080089",
            version: 1,
            createInfo: {
              time: 1449763200000,
              operator: {
                id: "=system=",
                operName: "系统用户"
              },
              enterprise: null
            },
            lastModifyInfo: {
              time: 1449763200000,
              operator: {
                id: "=system=",
                operName: "系统用户"
              },
              enterprise: null
            },
            enterprise: "8a2eb813518f1c5801518f1c6c080089",
            code: "0000",
            name: "总部",
            shortName: null,
            phone: null,
            address: null,
            contact: null,
            attachmentId: null,
            enabled: true,
            upper: null,
            level: 1,
            path: "/0000",
            namePath: "/总部",
            authentication: "211534962",
            isLeaf: false,
            storeType: null,
            orgType: null
          },
          level: 2,
          path: "/0000/1005",
          namePath: "/总部/杭州大区",
          authentication: null,
          isLeaf: null,
          storeType: null,
          orgType: "operateArea"
        },
        {
          uuid: "ebc5d2b9991b11e6b6816be1261807db",
          version: 0,
          createInfo: {
            time: 1477225684000,
            operator: {
              id: "admin",
              operName: "超级管理员"
            },
            enterprise: null
          },
          lastModifyInfo: {
            time: 1477225684000,
            operator: {
              id: "admin",
              operName: "超级管理员"
            },
            enterprise: null
          },
          enterprise: "8a2eb813518f1c5801518f1c6c080089",
          code: "1006",
          name: "苏州大区",
          shortName: "",
          phone: "",
          address: "",
          contact: "",
          attachmentId: null,
          enabled: true,
          upper: {
            uuid: "8a2eb813518f1c5801518f1c6c080089",
            version: 1,
            createInfo: {
              time: 1449763200000,
              operator: {
                id: "=system=",
                operName: "系统用户"
              },
              enterprise: null
            },
            lastModifyInfo: {
              time: 1449763200000,
              operator: {
                id: "=system=",
                operName: "系统用户"
              },
              enterprise: null
            },
            enterprise: "8a2eb813518f1c5801518f1c6c080089",
            code: "0000",
            name: "总部",
            shortName: null,
            phone: null,
            address: null,
            contact: null,
            attachmentId: null,
            enabled: true,
            upper: null,
            level: 1,
            path: "/0000",
            namePath: "/总部",
            authentication: "211534962",
            isLeaf: false,
            storeType: null,
            orgType: null
          },
          level: 2,
          path: "/0000/1006",
          namePath: "/总部/苏州大区",
          authentication: null,
          isLeaf: null,
          storeType: null,
          orgType: "operateArea"
        },
        {
          uuid: "ebc5d3ea991b11e6b6816be1261807db",
          version: 0,
          createInfo: {
            time: 1477225684000,
            operator: {
              id: "admin",
              operName: "超级管理员"
            },
            enterprise: null
          },
          lastModifyInfo: {
            time: 1477225684000,
            operator: {
              id: "admin",
              operName: "超级管理员"
            },
            enterprise: null
          },
          enterprise: "8a2eb813518f1c5801518f1c6c080089",
          code: "1007",
          name: "宁波大区",
          shortName: "",
          phone: "",
          address: "",
          contact: "",
          attachmentId: null,
          enabled: true,
          upper: {
            uuid: "8a2eb813518f1c5801518f1c6c080089",
            version: 1,
            createInfo: {
              time: 1449763200000,
              operator: {
                id: "=system=",
                operName: "系统用户"
              },
              enterprise: null
            },
            lastModifyInfo: {
              time: 1449763200000,
              operator: {
                id: "=system=",
                operName: "系统用户"
              },
              enterprise: null
            },
            enterprise: "8a2eb813518f1c5801518f1c6c080089",
            code: "0000",
            name: "总部",
            shortName: null,
            phone: null,
            address: null,
            contact: null,
            attachmentId: null,
            enabled: true,
            upper: null,
            level: 1,
            path: "/0000",
            namePath: "/总部",
            authentication: "211534962",
            isLeaf: false,
            storeType: null,
            orgType: null
          },
          level: 2,
          path: "/0000/1007",
          namePath: "/总部/宁波大区",
          authentication: null,
          isLeaf: null,
          storeType: null,
          orgType: "operateArea"
        },
        {
          uuid: "ebc5d517991b11e6b6816be1261807db",
          version: 0,
          createInfo: {
            time: 1477225684000,
            operator: {
              id: "admin",
              operName: "超级管理员"
            },
            enterprise: null
          },
          lastModifyInfo: {
            time: 1477225684000,
            operator: {
              id: "admin",
              operName: "超级管理员"
            },
            enterprise: null
          },
          enterprise: "8a2eb813518f1c5801518f1c6c080089",
          code: "1008",
          name: "上海大区",
          shortName: "",
          phone: "",
          address: "",
          contact: "",
          attachmentId: null,
          enabled: true,
          upper: {
            uuid: "8a2eb813518f1c5801518f1c6c080089",
            version: 1,
            createInfo: {
              time: 1449763200000,
              operator: {
                id: "=system=",
                operName: "系统用户"
              },
              enterprise: null
            },
            lastModifyInfo: {
              time: 1449763200000,
              operator: {
                id: "=system=",
                operName: "系统用户"
              },
              enterprise: null
            },
            enterprise: "8a2eb813518f1c5801518f1c6c080089",
            code: "0000",
            name: "总部",
            shortName: null,
            phone: null,
            address: null,
            contact: null,
            attachmentId: null,
            enabled: true,
            upper: null,
            level: 1,
            path: "/0000",
            namePath: "/总部",
            authentication: "211534962",
            isLeaf: false,
            storeType: null,
            orgType: null
          },
          level: 2,
          path: "/0000/1008",
          namePath: "/总部/上海大区",
          authentication: null,
          isLeaf: null,
          storeType: null,
          orgType: "operateArea"
        }
      ]
    };
  },
  methods: {
    // 分页
  },
  mounted() {
    // console.log(this.dataStaffs);
  },
  components: {
    AppBar
  }
};
</script>
